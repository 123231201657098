import React, { useContext } from 'react'
import Firebase from 'gatsby-plugin-firebase'

import { AuthProvider, AuthContext } from '../components/auth/auth'
import Layout from '../components/layout'

const Room = ({ location }) => {
  const roomLocationPath = location.pathname
  let matchingId = roomLocationPath.replace(/\/rooms\//, '')
  const [data, setData] = React.useState(null)

  React.useEffect(() => {
    Firebase
      .firestore()
      .collection('rooms')
      .doc(matchingId)
      .onSnapshot(function(doc) {
        const roomInfo = []
        const roomId = doc.id
        const roomIdAttr = `room-${roomId}`
        const background = doc.data().background
        roomInfo.push(
          <div
            className='room-container'
            style={{background: background}}
            key={roomIdAttr}
          >
            {/* <h1>{roomId}</h1> */}
            {/* <h1>{background}</h1> */}
            {/* <input type='color' className='color-picker' id='head' name='head'></input> */}
          </div>
        )

        // const changeColor = Firebase.firestore.FieldValue

        // function findOnClick() {
        //   // e.preventDefault
        //   const colorPicker = document.querySelector('.color-picker')
        //   colorPicker.attachEventListener('change', changeColor, false)

        //   function changeColor() {
        //     // e.preventDefault
        //     alert('lol')
        //   }

        // }

        setData(roomInfo)
      })
    
  })
  return (
    <AuthProvider>
      <Layout>
        {data}
      </Layout>
    </AuthProvider>
  )
}

export default Room